<template>
  <v-dialog eager v-model="dialog" :width="actualFormWidth" persistent>
    <BaseEditCardEntity
      :entity="currentEntity"
      :tab-items="editCardItems"
      entity-type="company"
      :is-edit="isEditForm"
      v-on:close="closeNewCompany"
      :save-item="saveCompany"
      :key="currentEntity.id"
      :is-loading="loadingCompany"
      :active-tab.sync="activeTab"
      ref="company-card-entity"
      :confirm-before-quit="autoplugsUpdated"
      :confirm-before-quit-message="autoplugsUpdatedMessage"
      v-on:update:activeTab="changeFormWidth($event)"
    >
      <template v-slot:CRM="{ entity }">
        <DisabledFieldsAlertMessage
          :condition="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)"
          statusNeededText="Admin & Finance"
        />
        <v-row wrap>
          <v-col cols="12">
            <v-text-field
              label="Name"
              v-model.trim="entity.name"
              required
              :error-messages="errorMessageName(entity ? entity.name: '')"
              :disabled="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="ID"
              :value="entity.id"
              disabled
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <ChildEntityAutocomplete
              v-model="entity.holding"
              :entitySearchType="'holding'"
              ref="holding-auto"
              :error-messages="errorMessageValueCantBeEmpty(entity.holding ? entity.holding.id : '', 'holding')"
              :disabled="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)"
            >
            </ChildEntityAutocomplete>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              label="Country"
              v-model="entity.country"
              :disabled="$store.getters.getLoadingCountries || disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)"
              :items="$store.getters.getCountries"
              item-text="name"
              item-value="id"
              return-object
              :loading="$store.getters.getLoadingCountries"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:PROPERTIES="{ entity }">
        <DisabledFieldsAlertMessage
          :condition="disabledIfNoRights(UserStatus.IS_AM)"
          statusNeededText="Account Manager"
        />
        <v-col cols="12">
          <v-select
            label="Autoplugs"
            :items="[{text: 'enabled', value: true}, {text: 'disabled', value: false}]"
            v-model="entity.hasAutoplugs"
            required
          >
          </v-select>
        </v-col>
      </template>
      <template v-slot:SEATS="{ entity }">
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn text @click="createNewSeat">
              <v-icon>
                add
              </v-icon>
              Add new seat
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-data-table
          :items="seatsOfCompany"
          :headers="headerSeat"
          :loading="loadingSeats"
          class="children-table"
          :footer-props="{
            itemsPerPageOptions: [20,50,100, {text: 'All', value: -1}]
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>
                <v-img
                  v-if="props.item && props.item.member && props.item.member.dsp"
                  :src="require('../../../assets/' + props.item.member.dsp + '.png')"
                  aspect-ratio="1"
                  heigth="1em"
                  width="1em"
                  avatar
                  class="dsp-img"
                  :title="props.item.member.dsp"
                >
                </v-img>
              </td>
              <td>
                {{ props.item.siege.name }}
              </td>
              <td>
                {{ props.item.member.externalId }}
              </td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-btn icon @click="editSeat(props.item)" :disabled="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)">
                        <v-icon v-on="on">edit</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span v-if="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)">
                    <DisabledFieldsAlertMessage
                      :condition="disabledIfNoRights(UserStatus.IS_ADMIN_AND_FINANCE)"
                      statusNeededText="Admin & Finance"
                    />
                  </span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
      <template v-slot:BILLING="{ entity }">
        <BillingForm
        :entity="entity"
        :entityType="'company'"
        ref="billingForm"
        >
        </BillingForm>
      </template>
      <template v-slot:PROSPECTING="{ entity }">
        <AutoplugTab
          :entity="entity"
          :seats-of-company="seatsOfCompany"
          :autoplugs-updated-and-not-validated-prop.sync="autoplugsUpdated"
          v-on:send-autoplug-alerts-to-company-form="getAutoplugs"
        >
        </AutoplugTab>
      </template>
      <template v-slot:HISTORY="{ entity }">
        <HistoryLogsComponent
          :entityId="currentEntity.id"
          entityType="company"
          :isReady="ready"
          :key="ready"
        ></HistoryLogsComponent>
      </template>
    </BaseEditCardEntity>
  </v-dialog>
</template>

<script>
import BaseEditCardEntity from '@/components/KeystoneV2/BaseEditCardEntity'
import { entityFormMixin } from '@/mixins/entityFormMixin'
import CompanyModel from '@/models/Keystone_v2/CompanyModel'
import { rulesMixin } from '@/mixins/rulesMixin'
import ChildEntityAutocomplete from '@/components/KeystoneV2/tools/ChildEntityAutocomplete'
import SiegeSettingModel from '@/models/Keystone_v2/SiegeSettingModel'
import BillingForm from '@/components/TableComponents/Form/BillingForm'
import { callEditFormMixin } from '@/mixins/callEditFormMixin'
import store from '../../../../store'
import { common } from '../../../../store/modules/common'
import { snakeCaseConverterMixin } from '@/mixins/snakeCaseConverterMixin'
import DisabledFieldsAlertMessage from '@/components/KeystoneV2/tools/DisabledFieldsAlertMessage'
import { UserStatus } from '../../../../types/user_types'
import AutoplugTab from '@/components/KeystoneV2/Form/Autoplug/AutoplugTab'
import HistoryLogsComponent from '../../TableComponents/Form/FormFragments/HistoryLogs.vue'

if (!store.state.common) store.registerModule('common', common)

export default {
  name: 'CompanyForm',
  mixins: [entityFormMixin, rulesMixin, callEditFormMixin, snakeCaseConverterMixin],
  components: { BaseEditCardEntity, ChildEntityAutocomplete, BillingForm, AutoplugTab, DisabledFieldsAlertMessage, HistoryLogsComponent },
  data: function () {
    return {
      formsWidth: {
        'default': '600px',
        'billing': '1200px',
        'history': '1000px'
      },
      actualFormWidth: '700px',
      editCardItems: [
        { title: 'CRM' },
        { title: 'PROPERTIES' },
        { title: 'SEATS', disabled: () => { return !this.isEditForm } },
        { title: 'BILLING' },
        { title: 'PROSPECTING' },
        { title: 'HISTORY', disabled: () => { return !this.isEditForm } }
      ],
      loadingCompany: false,
      /**
       * @type {SiegeSettingModel[]}
       */
      seatsOfCompany: [],
      headerSeat: [
        { text: 'dsp', value: 'siege.name', width: '5%' },
        { text: 'name', value: 'siege.name', width: '55%' },
        { text: 'member id', value: 'siege.member.externalId', width: '20%' },
        { text: 'edit', value: '', sortable: false, width: '20%' }
      ],
      loadingSeats: false,
      processingCompany: false,
      entityType: 'company',
      activeTab: 0,
      UserStatus: UserStatus,
      autoplugsUpdated: false,
      autoplugsUpdatedMessage: '<strong>Autoplug</strong> updated\n' +
        'Warning ! The item has not been validated and you are about to close the form.\n' +
        'Do you want to quit without validating the Autoplug changes ?',
      autoplugs: [],
      ready: false
    }
  },
  mounted () {
    if (this.$store.getters.getEditBilling) {
      this.activeTab = 3
      this.changeFormWidth(3)
    }
  },
  methods: {
    errorMessageIntegrity (name) {
      if (!this?.$store?.getters?.getCompanies) {
        return []
      }
      let companiesName = this.getEntitiesNameToTest(this.$store.getters.getCompanies)
      return this.tempErrorMessageIntegrity(name, companiesName)
    },
    errorMessageName (name) {
      return [...this.errorMessageIntegrity(name), ...this.errorMessageValueCantBeEmpty(name, 'name')]
    },
    defineMsaTypeItemsForNewForm () {
      this.$refs.billingForm.defineMsaTypeItems('company')
    },
    closeNewCompany () {
      this.dialog = false
      this.seatsOfCompany = []
      this.activeTab = 0
      this.actualFormWidth = this.formsWidth.default
      this.autoplugsUpdated = false
      this.callEditFormFunc(
        'openNewFormSiege',
        'openEditFormSiege'
      )
    },
    /**
     * @param company {CompanyModel}
     * @return {Promise<void>}
     */
    async saveCompany (company) {
      this.processingCompany = true
      let options = {
        name: company.name,
        holding_id: company.holding.id,
        hide_insight: !!company.hideInsight,
        has_autoplugs: !!company.hasAutoplugs
      }

      options.billing_account = this.snakeCaseConverter(this.$refs.billingForm.fetchData())

      if (company.country && company.country.id) {
        options.country_id = company.country.id
      }

      if (options.billing_account?.billing_entity?.country?.id) {
        options.billing_account.billing_entity.country_id = options.billing_account.billing_entity.country.id
      }

      let result = null
      let success = false

      if (this.isEditForm) {
        options.id = company.id
        result = await this.$store.dispatch('updateCompany', options)
      } else {
        result = await this.$store.dispatch('createCompany', options)
      }
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when process')
        this.$store.commit('setErrorMessageWithResponse', result)
        const barConfig = {
          type: 'error',
          message: 'Error on update or create company'
        }
        this.$store.commit('setSnackBarConfig', barConfig)
        this.$store.commit('setActiveSnackBar', true)
      } else {
        success = true
        console.warn('Process success')
        const barConfig = {
          type: 'success',
          message: 'Success !'
        }
        this.$store.commit('setSnackBarConfig', barConfig)
        this.$store.commit('setActiveSnackBar', true)
      }
      this.processingCompany = false
      this.$emit('refresh')

      if (!this.isEditForm && success) {
        this.currentEntity = new CompanyModel(result.data)
        this.$emit('update:isEditForm', true)
        this.activeTab = 2
      } else {
        this.closeNewCompany()
      }
    },
    async getChildOfCurrentEntity () {
      this.loadingSeats = true
      this.seatsOfCompany = []
      const data = {
        parent_id: this.currentEntity.id
      }
      const result = await this.$apiCaller.getKeystoneSiegeSettings(data)
      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when calling companies of holding')
        this.$store.commit('setErrorMessageWithResponse', result)
      } else {
        for (let i in result.data) {
          if (result.data.hasOwnProperty(i)) {
            this.seatsOfCompany.push(new SiegeSettingModel(result.data[i]))
          }
        }
      }

      this.loadingSeats = false
    },
    async getCurrentEntity (companyId) {
      this.loadingCompany = true
      this.currentEntity = new CompanyModel()

      const result = await this.$apiCaller.getCompany(companyId)
      if (this.$apiCaller.isResponseError(result)) {
        this.$store.commit('setErrorMessageWithResponse', result)
        this.closeNewCompany()
      } else {
        this.currentEntity = new CompanyModel(result.data)
      }

      this.loadingCompany = false
    },
    createNewSeat () {
      let cloned = this.$commonUtils.copyInstance(this.currentEntity)
      this.activeCallNewForm(cloned)
      this.$refs['company-card-entity'].__closeDialogProcess()
    },
    /**
     * @param siege {SiegeSettingModel}
     */
    editSeat (siege) {
      this.activeCallEditForm(siege)
      this.$refs['company-card-entity'].__closeDialogProcess()
    },
    /**
     * @param value {number} the tab id
     */
    changeFormWidth (value) {
      if ([3, 4].includes(value)) {
        this.actualFormWidth = this.formsWidth.billing
      } else if (value === 5) {
        this.actualFormWidth = this.formsWidth.history
      } else {
        this.actualFormWidth = this.formsWidth.default
      }
    },
    disabledIfNoRights (roles = null) {
      return !this.$commonUtils.hasUserCorrectStatus(this.$store.getters.getUserStatus, roles)
    },
    getAutoplugs (autoplugs) {
      this.autoplugs = autoplugs
      this.ready = true
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped>
.text-left {
  text-align: left;
}
.text-bold {
  font-weight: bold;
}
.text-italic {
  font-style: italic;
}
.redirect-slack {
  text-decoration: none;
}
.redirect-slack:hover {
  text-decoration: underline;
}
</style>
